import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import router from './router'
import { VueAxios } from './utils/request'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import enLocale from './lan/en'
import zhLocale from './lan/zh'
import fanLocale from './lan/fan'
import taiLocale from './lan/tai'
import VueI18n from "vue-i18n";
Vue.use(VueI18n)
const messages = {
  zh: zhLocale,
  en: enLocale,
  zh_TW: fanLocale,
  th: taiLocale
}

export function getLanguage() {
  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  if (language === 'zh-cn' || language === 'zh-sg') {
    return 'zh'; // 简体中文
  } else if (language === 'zh-tw' || language === 'zh-hk' || language === 'zh-mo') {
    return 'zh_TW'; // 繁体中文
  } else if (language.indexOf('th') != -1) {
    return 'th'; // 
  }
  return 'en'; // 默认为英文
}
 
// 创建 Vue I18n 实例： 使用 VueI18n 的构造函数创建一个名为 i18n 的实例。在构造函数中，通过传递当前语言代码和翻译信息来初始化实例。
const i18n = new VueI18n({
  globalInjection: true, //全局生效$t
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages,
  legacy: false
})

// const localLang = navigator.language.split('-')[0]?.toLocaleLowerCase()
// const storageLang = window.localStorage.getItem('locale')?.toLocaleLowerCase()
// const t = (localLang == 'th' || localLang == 'th') ? undefined : localLang
// const l = (localLang !== 'zh' && localLang !== 'en') ? t : localLang
// const c = (storageLang !== 'zh' && storageLang !== 'en') ? l : storageLang


// localStorage.setItem('locale', c || 'zh')

// const i18n = new VueI18n({
//   globalInjection: true, //全局生效$t
//   locale: c || 'zh',
//   messages,
//   legacy: false
// });
Vue.use(ElementUI);
Vue.use(VueAxios)
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
