<template>
    <div id="app">
      <router-view />
    </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style lang="scss">
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  
#app {
 
  text-align: center;
 
}
html,body{
  width: 100%;
  //height: 100%;
  margin: 0;
  overflow: auto;
}
 
nav {
  padding: 0px;
}

</style>
